<template>
  <ion-page>
    <div class="px-10 pt-12">
      <h2 class="mb-16">Sign In</h2>

      <ValidationObserver ref="signinForm" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(attemptSignIn)" method="post">
          <div>
            <ValidationProvider rules="required|email|max:255" mode="eager" vid="email" name="Email" v-slot="{ errors }">
              <input
                v-model="form.email"
                type="email"
                class="form-input"
                :class="errors.length ? 'border border-red-600' : ''"
                placeholder="yourmail@mail.com"
              />
              <p v-show="errors.length" class="w-full text-red-600 mt-1 text-sm"> 
                {{ errors[0] }}
              </p>
            </ValidationProvider>
          </div>

          <div class="mt-6">
            <ValidationProvider rules="required|min:8" mode="eager" vid="password" name="Password" v-slot="{ errors }">
              <input
                v-model="form.password"
                type="password"
                class="form-input"
                :class="errors.length ? 'border border-red-600' : ''"
                placeholder="*********"
              />
              <p v-if="errors.length" class="w-full text-red-600 mt-1 text-sm">
                {{ errors[0] }}
              </p>
            </ValidationProvider>
          </div>

          <div class="mt-6">
            <button class="form-input flex items-center bg-darkViolet" :class="{'opacity-75': loading}" type="submit">
              <span class="w-full text-gray-100 text-center">
                {{ loading === false ? 'Sign In' : 'Signing in...'}}
              </span>
            </button>
          </div>

          <div class="mt-8 w-full flex justify-center">
            No account?
            <router-link :to="{ name: 'signUpName' }" class="pl-1 text-blue-500 hover:text-blue-600">
              Create One
            </router-link>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </ion-page>
</template>

<script>
  import { mapActions } from 'vuex'
  import { SIGN_IN } from '@/store/types/actions'
  export default {
    name: 'SignIn',

    data() {
      return {
        form: {
          email: '',
          password: '',
        },

        loading: false
      }
    },

    methods: {
      ...mapActions({
        signIn: `auth/${SIGN_IN}`
      }),

      attemptSignIn() {
        this.loading = true

        this.signIn(this.form)
          .then(() => {
            this.$store.dispatch('auth/fetchStats')
            this.$router.push({ name: 'home' })
          })
          .catch(error => {
            this.loading = false

            if (error.response && error.response.data) {
              this.$refs.signinForm.setErrors(error.response.data.errors)
            }

            this.$ionic
              .toastController.create({
                color: 'danger',
                message: 'There was an error while trying to sign you in',
                duration: 3000,
              })
              .then(toast => toast.present())
          })
      }
    }
  }
</script>
